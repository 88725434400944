import React, { ChangeEvent, useEffect, useState, useRef } from 'react'
import CloseIcon from '../../asset/ineedhelp/CloseIcon'
import { useInitApi } from '../../store/initApi'
import axios, { CancelTokenSource } from 'axios'
import SendIcon from '../../asset/chat/SendIcon'
import { calculateTimeDifference } from '../../helpers/dateTime'
import DefaultProfileIcon from '../../asset/chat/DefaultProfileIcon'
import { useWellnessStreamStore } from '../../store/wellnessStream'
import { isMobile } from 'react-device-detect'
import { motion, useAnimation, PanInfo } from 'framer-motion';
import { Dialog, DialogPanel, DialogTitle, Transition, TransitionChild } from '@headlessui/react'
import { DialogContent } from '@mui/joy'

interface OpenComment {
    index?: number;
    feed: WellnessStreamProps,
    isOpen: boolean,
    closeIsOpen: () => void,
}

export default function CommentPopUp({ index, feed, isOpen, closeIsOpen }: OpenComment) {
    const apiToken = sessionStorage.getItem('api_token')
    // const storedUserData = sessionStorage.getItem('user');
    const controls = useAnimation();
    const [isDragging, setIsDragging] = useState(false);
    const COMMENT_SECTION_HEIGHT = '70vh';

    const [rows, setRows] = useState(1);
    const [message, setMessage] = useState<string>("")

    const [comments, setComments] = useState<CommentProps[] | null>(null)
    const [tempComments, setTempComments] = useState<string[]>([])
    const [isLoading, setIsLoading] = useState(false)
    const [isDisableSend, setIsDisableSend] = useState(true)

    const { initApi } = useInitApi()
    const { isInputFocused, setIsInputFocused, openId } = useWellnessStreamStore()
    const dialogRef = useRef<HTMLDivElement>(null);
    const textareaRef = useRef<HTMLTextAreaElement>(null);

    useEffect(() => {
        if (openId === index) {
            controls.start('visible');
        } else {
            controls.start('hidden');
        }
    }, [openId, controls]);
    const handleDragEnd = async (event: MouseEvent | TouchEvent | PointerEvent, info: PanInfo) => {
        // Get the height of the viewport
        const viewportHeight = window.innerHeight;
        // Calculate 5% of the viewport height as the close threshold
        const closeThreshold = viewportHeight * 0.05;

        // Allow closing only if dragged down
        if (info.offset.y > closeThreshold) {
            await controls.start('hidden');
            closeIsOpen();
        } else {
            // If not dragged past threshold, snap back to visible position
            controls.start('visible');
        }
        setIsDragging(false);
    };

    const variants = {
        hidden: {
            y: '100%',
            transition: {
                type: 'spring',
                damping: 30,
                stiffness: 300,
            },
        },
        visible: {
            y: 0,
            transition: {
                type: 'spring',
                damping: 30,
                stiffness: 300,
            },
        },
    };
    useEffect(() => {
        setIsLoading(true)
        let source = axios.CancelToken.source();
        const fetchComments = async (source: CancelTokenSource) => {
            if (!initApi) return;
            try {
                const res = await axios.post(`${initApi.url.features.feed.get_comment}`, {
                    event_id: feed.mapped_id
                }, { cancelToken: source.token, headers: { Authorization: `Bearer ${apiToken}` } })
                if (res.status === 200) {
                    const reverseComments = res.data.response.comments.reverse()
                    setComments(!isMobile ? res.data.response.comments : res.data.response.comments.reverse())
                    setIsLoading(false)
                }
            } catch (error) {
                if (axios.isCancel(error)) {

                } else {
                    console.log(error)
                    setIsLoading(false)

                }

            }
        }
        fetchComments(source)
        return () => {
            source.cancel();
        }
    }, [])
    useEffect(() => {
        useWellnessStreamStore.subscribe(state => {
            if (state.scroll && !isMobile) {
                closeIsOpen()
            }
        })
    }, [])
    useEffect(() => {
        const handleResize = () => {
            if (dialogRef.current) {
                dialogRef.current.style.height = `${window.innerHeight}px`;
            }
        };

        window.addEventListener('resize', handleResize);
        handleResize(); // Set initial height

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const handleComment = (event: ChangeEvent<HTMLTextAreaElement>) => {
        if (event.target.value.trim() !== '') {
            setIsDisableSend(false)
        } else {
            setIsDisableSend(true)
        }
        setMessage(event.target.value)
    }
    const handleKeyDown = (event: any) => {
        if (event.key === 'Enter' && !event.shiftKey) {
            event.preventDefault();
            postComment();
        }
    };
    const postComment = async () => {
        if (!initApi) return;

        try {
            setIsDisableSend(true)

            const res = await axios.post(`${initApi.url.features.feed.post_comment}`, {
                event_id: feed.mapped_id,
                reaction_type: 'comment',
                content: message,
                isMLC: true
            }, { headers: { Authorization: `Bearer ${apiToken}` } })
            if (res.status === 200) {
                setTempComments(
                    prev => [
                        message,
                        ...prev,
                    ])

                setMessage('')
                setIsDisableSend(false)
            }

        } catch (error) {
            setMessage('')
            setIsDisableSend(false)
        }
    }
    // useEffect(() => {
    //     if (!isMobile) {
    //         const handleFocus = () => {
    //             // Scroll to the textarea when it's focused
    //             setTimeout(() => {
    //                 textareaRef.current?.scrollIntoView({ behavior: 'smooth' });
    //             }, 100);
    //         };

    //         const handleBlur = () => {
    //             // Reset scroll position when textarea loses focus
    //             window.scrollTo(0, 0);
    //         };

    //         textareaRef.current?.addEventListener('focus', handleFocus);
    //         textareaRef.current?.addEventListener('blur', handleBlur);

    //         return () => {
    //             textareaRef.current?.removeEventListener('focus', handleFocus);
    //             textareaRef.current?.removeEventListener('blur', handleBlur);
    //         };
    //     }
    // }, []);

    return (
        isMobile ? (
            <motion.div
                initial="hidden"
                animate={controls}
                variants={variants}
                drag="y"
                dragConstraints={{ top: 0, bottom: 100 }}
                dragElastic={0}
                onDragStart={() => setIsDragging(true)}
                onDragEnd={handleDragEnd}
                className="fixed bottom-0 left-0 right-0 bg-transparent h-full flex flex-col z-10"
                style={{ touchAction: 'none' }}
            >
                <div className='h-1/2'></div>
                <div className='h-1/2 bg-white rounded-t-3xl shadow-lg'>
                    {/* Drag handle */}
                    <div className="flex justify-center p-2">
                        <div className="w-10 h-1 bg-gray-300 rounded-full" />
                    </div>

                    {/* Comments container */}
                    <div className="px-4 pb-4 h-full overflow-y-auto max-h-[400px]">
                        <h2 className="text-xl font-semibold mb-4">Comments</h2>

                        {/* Example comments - replace with your actual comments */}
                        <div className={`flex flex-col ${isInputFocused ? '' : 'justify-between'} h-full overflow-hidden max-h-[65%]`}>
                            <div className={`mb-8 overflow-auto ${isInputFocused ? 'max-h-[150px]' : ''}`}>
                                {tempComments.length > 0 && tempComments.map((tempComment, index) =>
                                    <div className='flex gap-4 items-center mt-4' key={index}>
                                        {initApi ? <img src={initApi.user.gamification.avatar} alt="" className='w-10 h-10 rounded-full' /> : <DefaultProfileIcon />}
                                        <div>
                                            <p className='font-bold'>{initApi ? `${initApi.user.gamification.handler}` : ''}</p>
                                            <p>{tempComment}</p>
                                            <p className='text-[#16182380]'>{(calculateTimeDifference(new Date().toString()))}</p>
                                        </div>
                                    </div>
                                )}
                                {isLoading ? <p>Loading...</p> : comments ? comments.map((comment, index) =>
                                    <div className='flex gap-4 items-center mt-4' key={index}>
                                        <img src={comment.user_profile} alt="" className='w-10 h-10 rounded-full' />
                                        <div>
                                            <p className='font-bold'>{comment.username}</p>
                                            <p>{comment.comment}</p>
                                            <p className='text-[#16182380]'>{comment.time}</p>
                                        </div>
                                    </div>
                                ) : tempComments.length < 1 ? <p>No Comments</p> : ""}
                            </div>
                        </div>
                        <div className='flex gap-4 items-center relative h-auto'>
                            <div className='bg-[#EAE9E9] w-full border rounded-full px-4 py-2'>
                                <textarea
                                    ref={textareaRef}
                                    maxLength={300}
                                    rows={rows}
                                    className='bg-[#EAE9E9] w-full border outline-none resize-none'
                                    name="input-chat"
                                    value={message}
                                    placeholder='Add comment....'
                                    onChange={handleComment}
                                    onKeyDown={message && !isDisableSend ? handleKeyDown : () => null}
                                />
                            </div>
                            {<SendIcon color={isDisableSend ? '#EAE9E9' : '#26B8EB'} className={`${message && !isDisableSend ? 'cursor-pointer' : 'cursor-not-allowed'}`} onClick={message && !isDisableSend ? postComment : () => null} />}
                        </div>
                    </div>
                </div>
            </motion.div>) : (
            <Transition appear show={isOpen}>
                <Dialog as="div" className="relative z-[99999] focus:outline-none lexend-deca-normal" onClose={() => null}>
                    <div className="inset-0" aria-hidden="true" />
                    <div className="fixed top-0 z-10 overflow-y-auto">
                        <div className="block w-fit justify-center p-4">
                            <TransitionChild
                                enter="ease-out duration-300"
                                enterFrom={`${isMobile ? 'bottom-[-100%]' : 'opacity-0'} transform-[scale(95%)]`}
                                enterTo={`${isMobile ? 'bottom-[0]' : 'opacity-100'} transform-[scale(100%)]`}
                                leave="ease-in duration-200"
                                leaveFrom={`${isMobile ? 'bottom-[0]' : 'opacity-100'} transform-[scale(100%)]`}
                                leaveTo={`${isMobile ? 'bottom-[-100%]' : 'opacity-0'} transform-[scale(95%)]`}
                            >
                                <DialogPanel className={`fixed ${isMobile ? 'bottom-0 left-0 h-full overflow-auto max-h-[400px]' : 'max-w-[300px] h-[500px] right-20'}  flex flex-col justify-center w-full rounded-t-xl bg-white p-6 border border-[#cccccc]`} style={{ overflow: 'auto' }}>
                                    <CloseIcon onClick={closeIsOpen} className='absolute top-5 right-5 cursor-pointer' />
                                    <DialogTitle>
                                        {/* Comments ({feed.number_of_comments + tempComments.length}) */}
                                        Comments
                                    </DialogTitle>
                                    <DialogContent>
                                        <div className={`flex flex-col ${isInputFocused ? '' : 'justify-between'} h-full overflow-hidden`}>

                                            <div className={`my-8 overflow-auto ${isInputFocused ? 'max-h-[150px]' : ''}`}>
                                                {tempComments.length > 0 && tempComments.map((tempComment, index) =>
                                                    <div className='flex gap-4 items-center mt-4' key={index}>
                                                        {initApi ? <img src={initApi.user.gamification.avatar} alt="" className='w-10 h-10 rounded-full' /> : <DefaultProfileIcon />}
                                                        <div>
                                                            <p className='font-bold'>{initApi ? `${initApi.user.gamification.handler}` : ''}</p>
                                                            <p>{tempComment}</p>
                                                            <p className='text-[#16182380]'>{(calculateTimeDifference(new Date().toString()))}</p>
                                                        </div>
                                                    </div>
                                                )}
                                                {isLoading ? <p>Loading...</p> : comments ? comments.map((comment, index) =>
                                                    <div className='flex gap-4 items-center mt-4' key={index}>
                                                        <img src={comment.user_profile} alt="" className='w-10 h-10 rounded-full' />
                                                        <div>
                                                            <p className='font-bold'>{comment.username}</p>
                                                            <p>{comment.comment}</p>
                                                            <p className='text-[#16182380]'>{comment.time}</p>
                                                        </div>
                                                    </div>
                                                ) : tempComments.length < 1 ? <p>No Comments</p> : ""}
                                            </div>

                                        </div>
                                        <div className='flex gap-4 items-center relative h-auto'>

                                            <div className='bg-[#EAE9E9] w-full border rounded-full px-4 py-2'>
                                                <textarea
                                                    ref={textareaRef}
                                                    maxLength={500}
                                                    rows={rows}
                                                    className='bg-[#EAE9E9] w-full border outline-none resize-none'
                                                    name="input-chat"
                                                    value={message}
                                                    placeholder='Add comment....'
                                                    onChange={handleComment}
                                                    onKeyDown={message && !isDisableSend ? handleKeyDown : () => null}
                                                // onFocus={() => setIsInputFocused(true)}
                                                // onBlur={() => setIsInputFocused(false)}
                                                />
                                            </div>
                                            {<SendIcon color={isDisableSend ? '#EAE9E9' : '#26B8EB'} className={`${message && !isDisableSend ? 'cursor-pointer' : 'cursor-not-allowed'}`} onClick={message && !isDisableSend ? postComment : () => null} />}
                                        </div>
                                    </DialogContent>
                                </DialogPanel>

                            </TransitionChild>
                        </div>
                    </div>

                </Dialog>
            </Transition>)
    )
}
