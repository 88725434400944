import Player from '../wellnessStream/Player'
// import PostItemSidebar from 'components/PostItem/PostItemSidebar'
// import PostItemBottom from 'components/PostItem/PostItemBottom'
import React from 'react'
import PostItemSidebar from './PostItemSidebar'
import { isMobile } from 'react-device-detect'
import MobilePlayer from './MobilePlayer'
import Title from './Title'

export default function PostItem({ feed, item, className, index }: any) {
 
  return (
    <article className={`post-item justify-center items-center ${className ?? ''}`} >
      {isMobile ? <MobilePlayer feed={feed} src={item.content} poster={item.image} /> : <Player feed={feed} src={item.content} poster={item.image}/>}
      <PostItemSidebar index={index} {...item} feed={feed} className={isMobile ? 'absolute right-4' : ''}/>
      {/* <PostItemBottom {...item} /> */}
      <style>{`
        .post-item {
          display: flex;
          height: calc(var(--vh, 1vh) * 100);
          scroll-snap-align: center;
          position: relative;
        }
      `}</style>
    </article>
  )
}
