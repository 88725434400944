import axios, { CancelTokenSource } from 'axios';
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import { FadeLoader } from 'react-spinners'
import { toast } from 'react-toastify';
import { TierLists } from '../types/tier';
import cryptojs from 'crypto-js'
import Blur from '../components/Blur';
import Error from '../components/Error';
import { UserDataProps } from '../types/common';
import { track } from '@amplitude/analytics-browser';
import {
    osName,
    deviceType,
} from 'react-device-detect';
interface Survey {
    url: string;
    image: string;
    title: string;
    page_url: string;
}
export default function Survey() {
    const [isLoaded, setIsLoaded] = useState(false);
    const { page_url } = useParams();
    const handleLoad = () => {
        setIsLoaded(true);
    };
    const [tierLists, setTierLists] = useState<TierLists>()
    const [surveyData, setSurveyData] = useState<Survey[]>([]);
    const [surveyExist, setSurveyExist] = useState<Survey>();
    const [userData, setUserData] = useState<UserDataProps>()
    const apiToken = sessionStorage.getItem('api_token');
    const storedUserData = sessionStorage.getItem('user');

    const fetchSurvey = async (source: CancelTokenSource) => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_ENV_URL}/api/app/mobile/get-surveys`, { headers: { Authorization: `Bearer ${apiToken}` }, cancelToken: source.token });
            if (response.status === 200) {
                setSurveyData(response.data);
            }
        } catch (error) {
            if (axios.isCancel(error)) {
            }
            else {
                toast.error('Error Survey');
            }
        }
    };
    useEffect(() => {
        track('screenView', { screen_name: 'SurveyScreen' })
        let source = axios.CancelToken.source();
        fetchSurvey(source);
        const tier = localStorage.getItem('tier')
        if (tier && process.env.REACT_APP_TIER_SECRET) {
            try {
                const decryptData = cryptojs.AES.decrypt(tier, process.env.REACT_APP_TIER_SECRET)
                const tierData = JSON.parse(decryptData.toString(cryptojs.enc.Utf8))
                setTierLists(tierData)
            } catch (error) {
            }
        }
        return () => {
            source.cancel();
        }
    }, []);

    useEffect(() => {
        if (surveyData) {
            // Find the survey object with matching page_url
            const survey = surveyData.find(survey => survey.page_url === page_url);
            if (survey !== undefined) {
                setSurveyExist(survey)
            }
            setIsLoaded(true)
        }
    }, [surveyData])
    useEffect(() => {
        if (storedUserData) {
            setUserData(JSON.parse(storedUserData));
        }
    }, [])
    return (
        <div className='ml-16 h-screen flex items-center justify-center'>
            {surveyExist && userData ? <iframe className='w-full h-screen' src={`${surveyExist?.url}?typeform-source=${process.env.REACT_APP_ENV_URL}#user_id=${userData.uuid}&org_id=${userData.current_org_id}&env=${process.env.REACT_APP_ENV}&device_info=device: ${deviceType}, operating system: ${osName}&class_id=${userData.org_roles[0].class_id ? userData.org_roles[0].class_id  : 0 }`} onLoad={handleLoad} style={{ display: isLoaded ? 'block' : 'none' }}></iframe> : ""}
            {!isLoaded && <FadeLoader />}
            {tierLists?.surveys ? <></> : <Blur />}
        </div>
    )
}
