
import React, { useEffect, useState } from 'react'
import CommentIcon from './Icons/Comment'
import ShareIcon from './Icons/Share'
import Avatar from './Icons/Avatar'
import activelike from '../../asset/lms/active-like.svg'
import CommentPopUp from './CommentPopUp'
import { defaultlike } from '../../asset';
import { useInitApi } from '../../store/initApi'
// import likeIcon from '../../asset/wellness_stream/like.svg'
// import likedIcon from '../../asset/wellness_stream/liked.svg'
import likeIcon from '../../asset/wellness_stream/heart_default.svg'
import likeActiveIcon from '../../asset/wellness_stream/heart _active.svg'
import commentIcon from '../../asset/wellness_stream/comment.svg'
import axios from 'axios'
import { isMobile } from 'react-device-detect'
import { useWellnessStreamStore } from '../../store/wellnessStream'

export default function PostItemSidebar({ feed, author, className, index }: any) {

  const apiToken = sessionStorage.getItem('api_token')
  const { initApi } = useInitApi()
  const [isOpenComment, setIsOpenComment] = useState(false)
  const { likeCounts, setLikeCounts, isCommentOpen, setIsCommentOpen, openId, setOpenId } = useWellnessStreamStore()
  const handleLike = async () => {
    if (!initApi) return;
    try {
      if (likeCounts && likeCounts[feed.mapped_id]) {
        !likeCounts[feed.mapped_id].isLiked ?
          setLikeCounts(prevLikeCounts => ({
            ...prevLikeCounts,           // Spread the previous likeCounts object
            [feed.mapped_id]: {
              likes: likeCounts[feed.mapped_id].likes + 1,
              isLiked: true
            }    // Update only the key you want (dynamically using feed.mapped_id)
          })) :
          setLikeCounts(prevLikeCounts => ({
            ...prevLikeCounts,           // Spread the previous likeCounts object
            [feed.mapped_id]: {
              likes: likeCounts[feed.mapped_id].likes - 1,
              isLiked: false
            }   // Update only the key you want (dynamically using feed.mapped_id)
          }))
      }
      const res = await axios.post(`${initApi.url.features.feed.post_react}`, {
        event_id: feed.mapped_id,
        reaction_type: 'like',
        on: feed.like ? false : true,
        isMLC: true
      }, { headers: { Authorization: `Bearer ${apiToken}` } })
    } catch (error) {

    }
  }
  const handleOpenComment = (index: number) => {
    if (index !== null && isMobile) {
      setOpenId(index)
      setIsCommentOpen(true)
    } else {
      console.log("Open comment")
      setIsOpenComment(true)
    }
  }

  const handleCloseComment = () => { 
    setOpenId(0)
    setIsCommentOpen(false)
  }

  return (
    <div className={`post-item-sidebar ${className ?? ''} ${isMobile ? 'bottom-[30%]' : 'bottom-[86px] mx-4'}`} onTouchStart={(e) => e.stopPropagation()}>
      <Avatar src={feed.author_profile} alt={author} />
      <div className='post-item-icons'>
        <button type='button' className='btn-icon post-item-icon' onClick={handleLike}>
          <img src={likeCounts[feed.mapped_id].isLiked ? likeActiveIcon : likeIcon} alt="" />
          <span className='text-white'>{likeCounts[feed.mapped_id].likes < 0 ? 0 : likeCounts[feed.mapped_id].likes}</span>
        </button>
        <button type='button' className='btn-icon post-item-icon' onClick={() => handleOpenComment(index)}>
          <img src={commentIcon} />
          <span className='text-white'>{feed.number_of_comments}</span>
        </button>
        {/* <button type='button' className='btn-icon post-item-icon'>
          <ShareIcon />
          <span>{'Share'}</span>
        </button> */}
      </div>
      {openId === index && isMobile ? <CommentPopUp index={index} feed={feed} isOpen={isCommentOpen} closeIsOpen={handleCloseComment} /> : ""}
      {!isMobile && isOpenComment ? <CommentPopUp feed={feed} isOpen={isOpenComment} closeIsOpen={() => setIsOpenComment(false)} /> : ""}
      <style>{`
        .post-item-sidebar {
          right: 15px;
          display: flex;
          flex-direction: column;
          align-items: center;
        }

        .post-item-icons {
          display: grid;
          grid-template-columns: 1fr;
          grid-gap: 12px;
          margin-top: 32px;
        }

        .post-item-icon {
          flex-direction: column;
        }

        .post-item-icon span {
          font-size: 13px;
          font-weight: 500;
          text-shadow: 1px 1px black
        }
      `}</style>
    </div>
  )
}
