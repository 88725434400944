import { create } from "zustand"

interface LikeObject {
    [key: number | string]: {
        likes: number;
        isLiked: boolean;
    }
}
type WellnessStreamProps = {
    scroll: boolean,
    setScroll: (value: boolean) => void
    globalMute: boolean
    setGlobalMute: (value: boolean) => void
    initUnmute: boolean
    setInitUnmute: (value: boolean) => void
    hasUserInteracted: boolean
    setIsUserInteracted: (value: boolean) => void
    isInputFocused: boolean
    setIsInputFocused: (value: boolean) => void
    isCommentOpen: boolean
    setIsCommentOpen: (value: boolean) => void
    dataLength: number
    setDataLength: (value: number) => void
    isScrollPrev: boolean
    setIsScrollPrev: (value: boolean) => void
    prevLength: number
    setPrevLength: (value: number) => void
    isPlayerIcon: boolean
    setIsPlayerIcon: (value: boolean) => void
    likeCounts: LikeObject,
    setLikeCounts: (updater: (prev: LikeObject) => LikeObject) => void;
    openId: number;
    setOpenId: (value: number) => void
}
export const useWellnessStreamStore = create<WellnessStreamProps>((set) => ({
    scroll: true,
    setScroll: (newValue) => set(() => ({ scroll: newValue })),
    globalMute: true,
    setGlobalMute: (newValue) => set(() => ({ globalMute: newValue })),
    initUnmute: true,
    setInitUnmute: (newValue) => set(() => ({ initUnmute: newValue })),
    hasUserInteracted: false, // Initially, no interaction
    setIsUserInteracted: (newValue) => set({ hasUserInteracted: newValue }),
    isInputFocused: false, // Initially, no interaction
    setIsInputFocused: (newValue) => set({ isInputFocused: newValue }),
    isCommentOpen: false, // Initially, no interaction
    setIsCommentOpen: (newValue) => set({ isCommentOpen: newValue }),
    dataLength: 0, // Initially, no interaction
    setDataLength: (newValue) => set({ dataLength: newValue }),
    isScrollPrev: false, // Initially, no interaction
    setIsScrollPrev: (newValue) => set({ isScrollPrev: newValue }),
    prevLength: 0, // Initially, no interaction
    setPrevLength: (newValue) => set({ prevLength: newValue }),
    isPlayerIcon: false, // Initially, no interaction
    setIsPlayerIcon: (newValue) => set({ isPlayerIcon: newValue }),
    likeCounts: {} as LikeObject,
    setLikeCounts: (updater) => set((state) => ({
        likeCounts: updater(state.likeCounts)
    })),
    openId: 0,
    setOpenId: (newValue) => set({ openId: newValue }),
}))