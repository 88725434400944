import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Home from './pages/Home';
import Login from './pages/Login';
import SignUp from './pages/SignUp';
import ForgetPassword from './pages/ForgetPassword';
import ChangePassword from './pages/ChangePassword';
import Gender from './pages/Gender';
import Journal from './pages/Journal';
import LearningSpace from './pages/LearningSpace';
import Insights from './pages/Insights';
import Surveys from './pages/Surveys';
import SmartGoals from './pages/SmartGoals';
import Setting from './pages/Setting';
import SafetyPlan from './pages/SafetyPlan';
import { ToastContainer } from 'react-toastify';
import INeedHelp from './pages/INeedHelp';
import SafetyPlanRevised from './pages/SafetyPlanRevised';
import { useDisabledOrgStore } from './store/disableSection';
import Survey from './pages/Survey';
import Chat from './pages/Chat';
import Feeds from './pages/Feeds';
import DisabledSection from './pages/DisabledSection';
import { useHomeStore } from './store/home';
import WellnessStream from './pages/WellnessStream';
import { useSystemStore } from './store/system';
import Maintenance from './pages/Maintenance';
import MaintenanceChecker from './middleware/MaintenanceChecker';
import { ProtectedRoutes } from './routes/protected.routes';
import LoginRoute from './routes/login.routes';
import { isMobile } from 'react-device-detect';
interface ConditionalRenderProps {
  disableModule: "chat_ai" | "all_feature" | "deleted_org" | undefined;
  children: React.ReactNode;
}

function App() {
  const apiToken = sessionStorage.getItem('api_token');
  
  const home = useHomeStore((state) => state.home);
  const disabledOrg = useDisabledOrgStore((state) => state.disabledOrg)
  const { maintenanceStart, maintenanceEnd } = useSystemStore();

  const ConditionalRender: React.FC<ConditionalRenderProps> = ({ disableModule, children }) => {
    if (disableModule !== null && disableModule !== 'chat_ai') {
      return <DisabledSection disabledModule={disableModule} />;
    }
    return <>{children}</>;
  };

  return (
    <Router>
      <ToastContainer position='top-center' />
      <MaintenanceChecker>
        <Routes>
          <Route path="/" element={apiToken ? <Navigate to="/home" /> : <Navigate to="/login" />} />
          <Route element={<ProtectedRoutes hideSidebar={isMobile ? true : false} />}>
            <Route path="/wellness-streams" element={<ConditionalRender disableModule={home?.disable_module}><WellnessStream /></ConditionalRender>} />
          </Route>
          <Route element={<ProtectedRoutes />}>
            <Route path="/home" element={<ConditionalRender disableModule={home?.disable_module}><Home /></ConditionalRender>} />
            <Route path="/insights" element={<ConditionalRender disableModule={home?.disable_module}><Insights /></ConditionalRender>} />
            

          </Route>
          <Route element={<ProtectedRoutes requiredRole="Student" />}>
            <Route path="/journal" element={<ConditionalRender disableModule={home?.disable_module}><Journal /></ConditionalRender>} />
            <Route path="/learning-space" element={<ConditionalRender disableModule={home?.disable_module}><LearningSpace /></ConditionalRender>} />
            <Route path="/surveys" element={<ConditionalRender disableModule={home?.disable_module}><Surveys /></ConditionalRender>} />
            <Route path="/smart-goals" element={<ConditionalRender disableModule={home?.disable_module}><SmartGoals /></ConditionalRender>} />
            <Route path="/setting" element={<ConditionalRender disableModule={home?.disable_module}><Setting /></ConditionalRender>} />
            <Route path="/feeds" element={<ConditionalRender disableModule={home?.disable_module}><Feeds /></ConditionalRender>} />
            <Route path="/survey/:page_url" element={<ConditionalRender disableModule={home?.disable_module}><Survey /></ConditionalRender>} />

            <Route path="/safety-plan" element={disabledOrg.login === 'deleted_org' ? <DisabledSection disabledOrg={disabledOrg} /> : <SafetyPlan />} />
            <Route path="/i-need-help" element={disabledOrg.login === 'deleted_org' ? <DisabledSection disabledOrg={disabledOrg} /> : <INeedHelp />} />
            <Route path="/safety-plan-revised" element={disabledOrg.login === 'deleted_org' ? <DisabledSection disabledOrg={disabledOrg} /> : <SafetyPlanRevised />} />
          </Route>

          <Route element={<ProtectedRoutes />}>
            <Route path="/chat" element={home?.disable_module === 'all_feature' ? <DisabledSection disabledModule={home?.disable_module} /> : <Chat />} />
          </Route>

          <Route element={<LoginRoute />}>
            <Route path="/login"
              element={disabledOrg.login === 'deleted_org' ? <DisabledSection disabledOrg={disabledOrg} /> : <Login />}>
            </Route>
          </Route>
          <Route path="/change-password" element={<ChangePassword />} />
          <Route path="/forget-password" element={<ForgetPassword />} />
          <Route path="/signup" element={<SignUp />} />
          <Route path="/gender" element={<Gender />} />
          <Route path="*" element={<Navigate to="/" />} />
          {/* Add more routes as needed */}
          <Route path="/maintenance" element={<Maintenance startDate={maintenanceStart} endDate={maintenanceEnd} />} />
        </Routes>
      </MaintenanceChecker>
    </Router>
  );
}

export default App;
